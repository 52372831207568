import { useContext, useRef, useEffect, useState } from "react";
import HelpMenu from "@components/menu/help-menu";
import SmallMenu from "@components/menu/help-menu/collapsed";
import helpMenuData from "@data/general/menu-03.json";
import AppContext from "../../context/app-context";
import LeftArrowBar from "../../components/arrow-bar/left-arrowbar";
import Logo from "@components/logo";
import footerData from "@data/general/footer-02.json";

const SideBar = () => {
    const resizableRef = useRef(null);
    const [resizing, setResizing] = useState(false);

    const { collapsed } = useContext(AppContext);

    useEffect(() => {
        if (resizing) {
            window.addEventListener("mousemove", handleResize);
            window.addEventListener("mouseup", stopResize);
        } else {
            window.removeEventListener("mousemove", handleResize);
            window.removeEventListener("mouseup", stopResize);
        }

        return () => {
            window.removeEventListener("mousemove", handleResize);
            window.removeEventListener("mouseup", stopResize);
        };
    }, [resizing]);

    const handleResize = (e) => {
        const resizableElement = resizableRef.current;
        const left = resizableElement?.getBoundingClientRect().left;

        resizableElement.style.width = e.pageX - left + "px";
    };

    const startResize = (e) => {
        e.preventDefault();
        setResizing(true);
    };

    const stopResize = () => {
        setResizing(false);
    };

    return (
        <div
            style={{
                display: "flex",
                height: "100%",
                justifyContent: "space-between",
                backgroundColor: "#292929",
            }}
        >
            {collapsed === false && (
                <div
                    className="sidebar_flex"
                    style={{ flexDirection: "column" }}
                >
                    <div style={{ position: "fixed", top: 80 }}>
                        <Logo logo={footerData.logo} />
                    </div>

                    <div className="d-flex left-sidebar" ref={resizableRef}>
                        <HelpMenu menu={helpMenuData} />
                    </div>
                </div>
            )}
            {collapsed === true && (
                <div className="sidebar_flex">
                    <div
                        className="d-flex "
                        ref={resizableRef}
                        style={{
                            minWidth: 45,
                            maxWidth: 45,
                        }}
                    >
                        <SmallMenu menu={helpMenuData} />
                    </div>
                </div>
            )}

            <LeftArrowBar startResize={startResize} />
        </div>
    );
};

export default SideBar;
