import { useContext } from "react";
import { MdArrowLeft } from "react-icons/md";
import { MdArrowRight } from "react-icons/md";
import { MdOutlineDragHandle } from "react-icons/md";
import AuthContext from "@context/app-context";

const LeftArrowBar = ({ startResize }) => {
    const { collapsed, setCollapsed } = useContext(AuthContext);

    return (
        <div
            style={{
                display: "flex",
                height: "100%",
                border: ".8px solid #ffffff14",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "12px",
                backgroundColor: "#292929",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                }}
            >
                {collapsed === false && (
                    <MdArrowLeft
                        style={{ cursor: "pointer", fontSize: 20 }}
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                    />
                )}

                {collapsed === true && (
                    <MdArrowRight
                        style={{ cursor: "pointer", fontSize: 20 }}
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                    />
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 8,
                    borderBottom: ".8px solid #ffffff14",
                    borderTop: ".8px solid #ffffff14",
                    cursor: collapsed === false && "col-resize",
                    width: collapsed && 12,
                }}
                onMouseDown={startResize}
            >
                {collapsed === false && (
                    <MdOutlineDragHandle
                        style={{ transform: "rotate(90deg)", fontSize: 20 }}
                    />
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                }}
            >
                {collapsed === false && (
                    <MdArrowLeft
                        style={{ cursor: "pointer", fontSize: 20 }}
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                    />
                )}

                {collapsed === true && (
                    <MdArrowRight
                        style={{ cursor: "pointer", fontSize: 20 }}
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default LeftArrowBar;
