import React from "react";
import Anchor from "@ui/anchor";
import clsx from "clsx";
import styles from "./styles.module.css";

const MenuElementCollapsed = ({ element }) => {
    return (
        <>
            {!element.hasOwnProperty("children") ? (
                <li className={clsx("nav-item", styles.collapsed_menu_element)}>
                    <Anchor path={element.path}>
                        {element?.icon && (
                            <i
                                className={clsx(element.icon)}
                                style={{
                                    fontSize: 25,
                                    width: "auto",
                                }}
                            />
                        )}
                    </Anchor>
                </li>
            ) : (
                <li
                    className={clsx(
                        "nav-item",
                        styles.collapsed_menu_element,
                        styles.collapsed_menu_element_has_children
                    )}
                >
                    <div className={clsx()}>
                        <Anchor path="#">
                            {element?.icon && (
                                <i
                                    className={clsx(element.icon)}
                                    style={{
                                        fontSize: 25,
                                        width: "auto",
                                    }}
                                />
                            )}
                        </Anchor>
                        <ul className={clsx(styles.collapsed_submenu)}>
                            {element.children.map((child) => (
                                <li
                                    key={child.id}
                                    className={clsx(
                                        styles.collapsed_submenu_element
                                    )}
                                >
                                    <Anchor
                                        path={child.path}
                                        className={clsx(styles.cse_link)}
                                    >
                                        <p
                                            className={clsx(
                                                styles.cse_link_text
                                            )}
                                        >
                                            {child.text}
                                        </p>
                                    </Anchor>
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>
            )}
        </>
    );
};

export default MenuElementCollapsed;
