import PropTypes from "prop-types";
import { useContext } from "react";
import AuthContext from "@context/app-context";
import clsx from "clsx";
import styles from "./styles.module.css";
import MenuElement from "./menu-element";

const HelpMenu = ({ menu }) => {
    const { profile } = useContext(AuthContext);

    return (
        <div style={{ width: "100%" }}>
            <ul className={clsx("mainmenu", styles.sidebar__ul)}>
                {profile
                    ? menu?.map(
                          (nav) =>
                              (!nav.hasOwnProperty("require_auth") ||
                                  (nav.hasOwnProperty("require_auth") &&
                                      !nav.roles) ||
                                  (nav.hasOwnProperty("require_auth") &&
                                      nav.roles?.includes(
                                          profile.role_id
                                      ))) && (
                                  <MenuElement
                                      key={nav.id}
                                      item={nav}
                                      time_param={nav.time_param}
                                  />
                              )
                      )
                    : menu
                          ?.filter((nav) => !nav.hasOwnProperty("require_auth"))
                          .map((item) => (
                              <MenuElement key={item.id} item={item} />
                          ))}
            </ul>
        </div>
    );
};

HelpMenu.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HelpMenu;
