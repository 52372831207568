import PropTypes from "prop-types";
import { useContext } from "react";
import AppContext from "../../../context/app-context";
import MenuElementCollapsed from "./menu-element-collapsed";

const HelpMenu = ({ menu }) => {
    const { profile, collapsed } = useContext(AppContext);

    return (
        <div style={{ width: "100%" }}>
            <ul
                style={{
                    listStyle: "none",
                    marginTop: "1rem",
                    padding: "0px",
                    width: "100%",
                }}
            >
                {profile
                    ? menu?.map(
                          (nav) =>
                              (!nav.hasOwnProperty("require_auth") ||
                                  (nav.hasOwnProperty("require_auth") &&
                                      !nav.roles) ||
                                  (nav.hasOwnProperty("require_auth") &&
                                      nav.roles?.includes(
                                          profile.role_id
                                      ))) && (
                                  <MenuElementCollapsed
                                      key={nav.id}
                                      element={nav}
                                  />
                              )
                      )
                    : menu
                          ?.filter((nav) => !nav.hasOwnProperty("require_auth"))
                          .map((item) => (
                              <MenuElementCollapsed
                                  key={item.id}
                                  element={item}
                              />
                          ))}
            </ul>
        </div>
    );
};

HelpMenu.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HelpMenu;
