import React, { useState } from "react";
import Anchor from "@ui/anchor";
import clsx from "clsx";
import styles from "./styles.module.css";
import { BiCaretDown } from "react-icons/bi";

const MenuElement = ({ item }) => {
    const [subMenuHeight, SetSubMenuHeight] = useState(0);
    const toggleSubMenu = () => {
        if (subMenuHeight === 0) {
            SetSubMenuHeight(40 * item.children.length);
        } else {
            SetSubMenuHeight(0);
        }
    };
    return (
        <li
            style={{
                whiteSpace: "nowrap",
            }}
            className={clsx("nav-item menu__list", styles.sidebar__li)}
        >
            {!item.hasOwnProperty("children") ? (
                <Anchor
                    path={`${item.path}${
                        item.time_param ? `?t=${Date.now()}` : ""
                    }`}
                >
                    {item?.icon && (
                        <i className={clsx(item.icon, styles.sidebar__icon)} />
                    )}
                    {item.text}
                </Anchor>
            ) : (
                <div className="">
                    <div
                        className="d-flex justify-content-between"
                        onClick={() => {
                            toggleSubMenu();
                        }}
                        role="presentation"
                    >
                        <Anchor path="#">
                            <i
                                className={clsx(
                                    item.icon,
                                    styles.sidebar__icon
                                )}
                            />
                            {item.text}
                        </Anchor>
                        <BiCaretDown
                            className={clsx(styles.dropdown_icon)}
                            size={16}
                            style={{
                                rotate:
                                    subMenuHeight === 0 ? "0deg" : "-180deg",
                            }}
                        />
                    </div>
                    <div
                        className={clsx(styles.submenu_wrapper)}
                        style={{ height: subMenuHeight }}
                    >
                        <ul className={clsx(styles.submenu)}>
                            {item.children.map((child) => (
                                <li
                                    key={child.id}
                                    className={clsx(styles.submenu_element)}
                                >
                                    <Anchor path={child.path}>
                                        {child.text}
                                    </Anchor>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </li>
    );
};

export default MenuElement;
